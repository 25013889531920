$primary-color: #1ed761;
$menu-active: $primary-color;
$menu-active-background: transparent linear-gradient(90deg, #1ed76116 0%, #1ed76100 100%) 0% 0% no-repeat padding-box;
$secondary-color: $primary-color;

$logo-img-normal: 11vw;
$logo-img-normal-responsive: 11vw;
$logo-min-width: 160px;
$logo-min-height: 47px;
$logo-text-img: 13vw;
$logo-text-img-responsive: 13vw;
$no-profile: url('../../assets/no-profile.svg');
//graphs-stream-individual
$graph-radio-checked: rgba(30, 215, 97, 0.1);
$graph-color: $primary-color;
$delete-btn: #DD4848;
$body-bg: #ffffff;
$date-picker: $primary-color;
$date-picker-text-color:$body-bg;
$tab-weight: 400;
$latest-version-bg: $primary-color;
$latest-version-color: #fff;
$file-not-supported-border:#F0B220;
$model-btn-green: $primary-color;
$mandatory-symbol:#E25351;

$active-label-color: #2BC362;
$active-element-bg: #ECF9F1;
$secondary-button-bg: #45C36D;
$error-block-color: #E25351;
$secondary-text-color: $primary-color;

$loader-height: calc(100vw * 0.019);
$loader-width: calc(100vw * 0.019);

//viewers-feedback-stars
$full-star: url('../../assets/full-star.svg');
$empty-star: url('../../assets/empty-star.svg');
$half-star: url('../../assets/half-star.svg');
$quarter-star: url('../../assets/quarter-star.svg');
$one-star: url('../../assets/one-star.svg');
$three-quarter-star: url('../../assets/three-quarter-star.svg');

//push-notification
$status-sent: #d1f5df;

//app version
$dropdown-hover-color:#D6FEE4; //#8fecb0;
$dropdown-selected-bg:#d0d0d0;
$dropdown-selected-color:#11263F;
$select-file-color:$primary-color;
$choose-file-color: #0B8BF8;

$target-countries-dropdown-hover:#8FECB0;

$radio-unchecked : url('../../../assets/Ads/radio-unchecked.svg');
$radio-checked : url('../../../assets/Ads/radio-checked.svg');

$share-btn-outline:#dd4848;
$publish-btn-outline: #d8d8d8;
$version-tag-latest:#d1f5df;
$version-tag-text-color:#11263F;
$version-tag-old:#e5e7eb;
$code-dropdown-bg-color:$primary-color;
$code-dropdown-color:#fff;
$deprecate-cancel-btn:#E9EAEC;

//multi-select
$multi-select-checkbox-hover:#3AD873;

//users
$user-menu-dropdown: $primary-color;
$user-menu-button-hover: $primary-color;
$user-menu-selected: #00000033;
$user-button-hover-text-color:#fff;
$user-model-item-border:$secondary-color;
$user-model-selected-bg:#1ED76120;
$success-model-border: $primary-color;

$user-selected-tick-icon:url(https://i.ibb.co/By1bcTs/user-tick.png);


//home

$exclusive-box-border: transparent;
$exclusive-box-bg: #F7E27F;

//details
$mat-toggle-bg: #34C759;

//branding
$theme-icon-color:#B6EEBF;
$theme-icon-bg: rgba(182, 238, 191, 0.37);
$banner-box-bg:#90E59E;
$banner-bg:#B6EEBF4D;

//page
$layout-hover-bg: transparent;

//video module
$source-tab-selected-bg:#b5eebe5e;

//email-templates
$email-tabs-active-color: #2bc362;
$email-tabs-active-bg: #ECF9F1;

//video list module
$video-type-dropdown-hover-bg: #8FECB0;
$video-type-dropdown-selected-bg:hsla(0, 0%, 0%, 0.12);

// cancel model
$failure-popup-left-border: #DD4848;
$event-archival-popup:#E25351;

//videos
$radio-bg: #b6eebf5e;
$date-picker-color: #fff;
$tag-copy-active: url("../../assets/Channels/tag-copy-green.svg") no-repeat;
$convert-button-color:#2873cc;
$finished-btn-color:#1a7dbb;
$finished-bg-color:#d2eeff;
$finished-border-color:#1a7dbb;

//events
$cancel-btn-color: #F7F8FA;

//Named Tags
$tagType-dropdown-hover-bg: #8FECB0;
$tagType-dropdown-selectedBg:#D6FEE4;

//header
$resetAll-btn-normal-color: #11263F;
$resetAll-btn-hover-color: #DD4848;
$resetAll-btn-active-color: #BD3636;

//users
$delete-btn-bg:#F8DADA;
$delete-btn-textColor:#DD4848;

//create-channel
$create-channel-dropdown-color: #11263F;
$create-channel-dropdown-hover-bg: $video-type-dropdown-hover-bg;


//error
$error-text-color:#E25351;

$delete-hover: url(../../assets/Channels/page/delete-hover.svg);
$delete-active: url(../../assets/Channels/page/delete-active.svg);
$delete-active-border: #C43D3B;
$btn-green: $primary-color;
$btn-neutral: $primary-color;

//sub-channels
$owner-dropdown-hover-bg:#d6ffe4;
$owner-dropdown-selected-bg:#d6ffe4;
$streaming-dropdown-hover-bg:$primary-color;
$streaming-error-dropdown-hover-bg:#FDF3F3;
$streaming-dropdown-selected-bg:#d0d0d0;
$streaming-dropdown-hover-text-color:#fff;
$uploads-success-dropdown-border:$primary-color;
$accent-color:none;

//bulk-edit-popup
$changes-bg:#0b8bf8;
$changes-border:#d9eeff;
$recipients-bg:#45C36D;
$recipients-border:$primary-color;
$input-active-border-color: $changes-bg;
$input-active-bg-color:$changes-border;
$bulk-edit-dd-hover:#D6FEE4;
$bulk-edit-dd-selected:rgba(0,0,0,.12);
$failed-bulk-streams-color:#E25351;
$email-btn-bg:$primary-color;

//clips
$clips-link-color:#007bff;

//settings tab
$consent-label-text-color: #2BC362;
$consent-label-active-bg:#ECF9F1;
$settings-add-button-bg:#16BD53;
$settings-hover-btn-color:#14A247;
$settings-active-btn-color:#288349;
$testimonial-cancel-btn:$primary-color;
$testimonial-cancel-btn-border:none;
$settings-latest-version-bg:#D1F5DF;
$settings-latest-version-label-color:#17C757;
$settings-latest-text-color:#333333;
$view-report-text-color:#23C25D;
$attributes-expand-border: $primary-color;
$attributes-error-text-color: #FB3836;
$attributes-cancel-btn-text-color: $primary-color;
$attributes-cancel-btn-border-color: $primary-color;

//-- consents 
$create-group-color: #22CA5F;
$drag-cancel: #E9EAEC;

// tabbed pages 
$module-type-radio-btn-bg: rgba(30, 215, 97, 0.10);