
@import 'video.js/dist/video-js.min.css';
$yo-videojs-player-asset-path: "/assets/player";
@import '../projects/yo-videojs-player/src/styles/videojs-custom-style.scss';
@import "variables";
// @import '../node_modules/ngx-datetime-picker/ngx-datetime-picker.css';
@font-face {
	font-family: 'Proxima';
	src: url(assets/fonts/Proxima-Nova-Regular.otf);
	font-weight: 500;
}

@font-face {
	font-family: 'Proxima';
	src: url(assets/fonts/Proxima-Nova-Bold.otf);
	font-weight: bold;
}

@font-face {
	font-family: 'Proxima';
	src: url(assets/fonts/Proxima-Nova-Medium.otf);
	font-weight: 550;
}
@font-face {
	font-family: 'Proxima';
	src: url(assets/fonts/Proxima-Nova-Sbold.otf);
	font-weight: 600;
}
body {
	margin: 0;
	font-family: 'Proxima';
	overflow: hidden;
	// background: #F3F4F6;
	background: #F1F1F1;
	scrollbar-visibility: hidden;
	scrollbar-width: none;
}

.container-fluid {
	scrollbar-width: none;
}

html,
body {
	height: 100%;
}

// ::-webkit-scrollbar {
// 	display: none !important;
// 	width: 0; 
// 	height: 0;
//     background: transparent; 
// }
// ::-webkit-scrollbar {
// 	width: 0px;
// 	height: 0px;
// }


/* Optional: show position indicator in red */

// ::-webkit-scrollbar-thumb {
// 	background: transparent;
// 	width: 0;
// 	__<<ngThemingMigrationEscapedComment1>>__
// 	height: 0;
// }

// ::-webkit-scrollbar {
// 	display: none;
// }


/* Safari */

// ::-moz-scrollbars {
// 	display: none;
// }

// ::-o-scrollbar {
// 	display: none;
// }


/* opera */

// ::-google-ms-scrollbar {
// 	display: none;
// }

// ::-khtml-scrollbar {
// 	display: none;
// }

.navbar-brand {
	margin-top: 5px;
}

.headercatagories ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.p-40px {
	padding: 40px !important;
}

.m-32 {
	margin-right: 32px;
}

.mb-13px {
	margin-bottom: 13px !important;
}

.mb-1rem {
	margin-bottom: 1rem !important;
}

.mr-1rem {
	margin-right: 1rem !important;
}

.mt-25px{
	margin-top: 25px !important;
}
.mt-50px {
	margin-top: 50px !important;
}

.mt-1rem {
	margin-top: 1rem !important;
}
.mt-minus-24px{
	margin-top: -24px !important;
}
.mw-25px {
	min-width: 25px !important;
}

.mw-125px {
	min-width: 125px !important;
}
.mw-200px{
	min-width: 200px !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.p-1rem-0{
	padding: 1rem 0 !important;
}
.transparent-text {
	color: transparent !important;
}

table {
	width: 100%;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-table,
.mat-tab-group {
	font-family: 'Proxima';
}

.mat-table thead {
	font-size: 12px;
	color: #777777;
	text-transform: uppercase;
	background: #F9FAFB;
	border-bottom: 1px solid #E5E7EB;
	border-radius: 6px 6px 0px 0px;
}

.mat-row,
.mat-header-row,
.mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	border-bottom-color: #E5E7EB;
}

.mat-table thead th {
	border: none;
}

.mat-header-cell {
	color: #777777;
}

.mat-table tr.mat-row {
	height: 80px !important;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
	margin: 0 !important;
}

.mat-cell,
.mat-footer-cell {
	color: #333333;
}

.headercatagories .md-drppicker {
	position: fixed !important;
	top: auto !important;
	right: 0px !important;
	left: auto !important;
}
.mat-ink-bar{
	background-color: $primary-color !important;
	height: 2px !important;
	border-radius: 10px !important;
}
.mat-tab-label-active{
	opacity: 1 !important;
	color: $primary-color !important;
	cursor: default !important;
}
.mat-tab-label-content{
	font-family: 'Proxima';
	font-size: 16px;
}
.mat-tab-header { 
	border-bottom: none !important; 
}
.mat-tab-labels .mat-tab-label { 
	padding: 0 0 !important; 
	min-width: 10px !important; 
	margin: 0px 15px; 
	cursor: pointer;
}
.md-drppicker .ranges ul li button.active,
.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker .btn {
	background-color: $primary-color !important;
}
// .main-body-header .calendar,
// .create-season-tickets-form .calendar {
//     position: relative !important;
// .ngx-picker--btn__minute{
// 	margin-right: 4rem !important;
// }
// .ngx-picker--btn__hour{
// 	margin-left: 4rem !important;
// }
.mat-tab-label{
	padding: 0 !important;
}
// .mat-tab-labels{
// 	padding-left: 19px;
// }
// .main-body-header .md-drppicker .ranges ul li button.active,
// .main-body-header .md-drppicker td.active,
// .main-body-header .md-drppicker td.active:hover,
// .main-body-header .md-drppicker .btn {
// 	background-color: $primary-color !important;
// }
.main-body-header .md-drppicker .ranges ul li button.active,
.main-body-header .md-drppicker .btn{
	background-color: $primary-color !important;
}
.main-body-header .md-drppicker td.active,
.main-body-header .md-drppicker td.active:hover{
	background-color: $date-picker !important;
	color: #202223 !important;
}
.mat-tab-label-active{
	color: $primary-color !important;
	font-weight: 600;
}

.main-body-header .md-drppicker .btn {
	padding: 0 12px !important;
	line-height: 32px !important;
	font-size: 14px !important;
}

.main-body-header .md-drppicker .btn.btn-default {
	color: #000;
	background-color: #dcdcdc !important;
	margin-right: 10px;
}

.main-body-header .md-drppicker .btn.btn-default svg {
	display: none;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
    background-color: $date-picker!important;
	color: $date-picker-text-color !important;
}
.calendar--day__selected, .calendar--day:hover {
    background-color: $primary-color;
	color: #fff;
}
.main-body {
	// background: #F3F4F6;
	background: #F1F1F1;
	padding: 24px 32px 0px 32px;
	min-height: calc(100vh - 109px);
	margin-top: 109px;

	&.main-body-event{
		margin-top: 94px;
	}
}

.main-body-header {
	margin-bottom: 12px;
}

.main-body-header .header-text,
.stream-indiv-view-container .header-text {
	color: #2E394C;
	font-size: 20px;
	font-weight: 500;
}

.main-body-create-season {
	min-height: calc(100vh - 150px) !important;
	background: #fff !important;
	overflow: auto;
}
.main-body-channel{
	background: #ffffff;
	min-height: unset !important;
	padding-top: 12px !important;
	height: 124px !important;
}
// .main-body::-webkit-scrollbar {
// 	width: 0px;
// 	height: 0;
// 	background: transparent;
// }
.header-shadow {
	// position: relative;
	// box-shadow: 0px 1px 3px #00000014;
	position: fixed;
	box-shadow: 0px 1px 3px #00000014;
	width: calc(100% - 80px);
	z-index: 999;
	background: white;
}

// th.mat-header-cell:first-of-type,
// td.mat-cell:first-of-type,
// td.mat-footer-cell:first-of-type {
// 	padding-left: 1rem !important;
// }
tr.mat-header-row {
	height: 40px !important;
}

th.mat-header-cell {
	padding: .5rem 1rem !important;
	white-space: nowrap;
}

td.mat-cell,
td.mat-footer-cell {
	padding: 1rem !important;
	white-space: nowrap;
}

.flex-centered {
	padding: 6px 0;
	display: flex;
	align-items: center;
}

.btn-green {
	color: #fff;
	background: $primary-color;
}

.btn-green:hover {
	color: #fff;
	background: $primary-color;
}

.btn-transparent {
	color: transparent;
	background: transparent;
	border: 0;
	padding: 0;
}

.export-btn {
	font-size: 14px;
	color: #9EACC4;
	letter-spacing: 0px;
	background: white;
	padding: 5px 12px;
	border: 1px solid #E5E7EB;
	border-radius: 16px;
	cursor: pointer;
	width: auto;
	display: flex;
	align-items: center;
	outline: none;
}

.export-btn img {
	margin-right: 6px;
}

.btn:focus,
button:focus {
	outline: 0;
	box-shadow: none;
}

.modal {
	box-shadow: 0px 1px 4px #000C2614;
	border-radius: 6px;
}

.modal-dialog {
	margin-top: 8.5rem;
}

.modal .modal-body {
	font-size: 16px;
	color: #777777;
	padding: 2rem !important;
}

.modal .modal-body h2 {
	color: #000C26;
}

.modal .modal-body .rounded-delete {
	margin-right: 1rem;
}

.modal .modal-body .rounded-delete img {
	width: 40px;
	height: 40px;
	background: #FAE7E7;
	border-radius: 50px;
	text-align: center;
	line-height: 40px;
}

.modal-footer {
	background: #F3F4F6;
	padding: .5rem 2rem .5rem;
}

.modal-footer .btn-red {
	color: #ffffff;
	background: #DD4848;
	border-radius: 4px;
	padding: 6px 1rem;
}

.modal-footer .btn-white {
	color: #000000;
	background: #ffffff;
	border-radius: 4px;
	padding: 6px 1rem;
	border-color: #E5E7EB;
}

.only-show-on-hover {
	width: 0;

	.edit-icon{
		&:hover{
			content: url('/assets/Datatable/edit-hover.svg');
		}

		&:active{
			content: url('/assets/Datatable/edit-active.svg');
		}
	}

	.view-icon{
		&:hover{
			content: url('/assets/Datatable/view-hover.svg');
		}

		&:active{
			content: url('/assets/Datatable/view-active.svg');
		}
	}

	.delete-icon{
		&:hover{
			content: url('/assets/Datatable/delete-hover.svg');
		}

		&:active{
			content: url('/assets/Datatable/delete-active.svg');
		}
	}
}

.only-show-on-hover img:focus {
	outline: 0;
	box-shadow: none;
}

.date-input {
	cursor: pointer;
}

.filter-option>.mat-pseudo-checkbox {
	display: none !important;
}

.filter-sidenav .mat-list-item-content {
	padding: 0 10px !important;
	font-size: 0.875rem !important;
}

.filter-sidenav .mat-select-trigger {
	font-size: 1rem !important;
}

.create-season-tickets-form {
	max-width: 500px;
	margin-bottom: 2rem;
}

.create-season-tickets-form input, .create-season-tickets-form .mat-form-field-infix {
	border: 1px solid #A5B6D7;
	border-radius: 4px;
	// box-shadow: 0 1px 2px #0000000a;
	background: #fff;
	width: 500px;
	height: 32px;
}
.create-season-tickets-form textarea{
	border: 1px solid #dddddd;
	border-radius: 4px;
	// box-shadow: 0 1px 2px #0000000a;
	background: #fff;
	width: 500px;
	height: auto;
}

.push-notifi-form .mat-form-field-infix{
	width: 245px !important;
}
.push-notifi-form .url-input input{
	width: 245px;
}
.currency-input-select .mat-form-field-infix {
	width: 247px !important;
}

.create-season-tickets-form .mat-form-field-type-mat-select .mat-form-field-label,
.create-season-tickets-form input,
.create-season-tickets-form .mat-form-field-infix {
	padding-left: 8px;
}

.create-season-tickets-form .input-group-text {
	border: 1px solid #dddddd;
	padding: 4px 8px;
}

.create-season-tickets-form .validity-date-picker {
	width: 516px;
	margin-bottom: 24px;
}
.create-season-tickets-form .currency-input-select {
	width: 516px;
}

.create-season-tickets-form .validity-date-picker input {
	border-radius: 0 4px 4px 0;
	width: 100%;
}

.create-season-tickets-form .form-control {
	padding: .375rem 8px;
	margin-bottom: 24px;
	color: #000000;
}

.create-season-tickets-form .mat-form-field-appearance-legacy .mat-form-field-underline {
	height: 0px;
}

.create-season-tickets-form .mat-form-field-appearance-legacy .mat-form-field-underline,
.create-season-tickets-form .mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: transparent;
}

.create-season-tickets-form .mat-form-field-empty.mat-form-field-label,
.create-season-tickets-form .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	display: none !important;
}

.create-season-tickets-form .mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 24px;
}

.create-season-tickets-form .my-1 {
	margin: 0 !important;
}

.create-season-tickets-form .input-group {
	flex-wrap: nowrap;
}

.mat-option {
	padding: 0px 8px !important;
}

.mat-option-text input {
	// width: 100% !important;
	border: 0 !important;
	font-family: 'Proxima';
}

.mat-option-text input:focus,
.form-control:focus {
	outline: 0 !important;
	box-shadow: none !important;
	border-color: $primary-color !important;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
	box-shadow: 0 1px 2px #00000036 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: $create-channel-dropdown-color !important;

	&:hover{
		color: $create-channel-dropdown-color !important;
	}
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: $primary-color !important;
	border: none !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled{
	background: #68e094 !important;
}

.btn-primary, .btn-primary:focus, .btn-primary:active, .btn-primary:hover {
	background: $primary-color !important;
	border-color: $primary-color !important;
	box-shadow: none !important;
	outline: 0 !important;
}
.btn-outline{
	border-color: $primary-color
}
.btn-outline-primary {
	border-color: $primary-color;
	color: $primary-color;

	&:hover, &:active, &:active {
		background: $primary-color;
		border-color: $primary-color;
		color: #fff;
	}
}

// .mat-select-panel span{
// 		padding: 8px !important;
// 	}
.mat-select-panel {
	// 	min-width: 500px !important;
	// margin-left: 7px !important;
	min-width: calc(100% + 18px) !important;
	margin-left: 7px !important;
	min-height: 80px;
	scrollbar-visibility: hidden;
	scrollbar-width: none;
}
.mat-select-panel.ng-animating {
    display: none;
}
.mat-select-panel::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
.no-record-found {
	padding: 8px;
}

.white-background {
	background: #fff;
	padding: 0 0px 32px;
	margin: 0;
	border-radius: 0 0 6px 6px;
}

.grey-background {
	background: #F3F4F6;
	padding: 5px 33px 10px;
}

.video-height {
	min-height: 300px;
}

#gameViewModal .modal-body ol {
	padding-left: 1rem !important;
}

#gameViewModal .modal-body ol li {
	margin-bottom: .5rem !important;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
	color: #777777 !important;
	-webkit-text-fill-color: #777777 !important;
	font-family: 'Proxima';
}

.cdk-overlay-pane {
	transform: translateX(-16px) translateY(-13px) !important;
}

// .season-ticket-cropper .cropper .resize {
// 	cursor: default !important;
// }

.season-ticket-cropper .cropper .resize .square {
	background: transparent !important;
}

// .season-ticket-cropper .cropper .resize-bar {
// 	cursor: default !important;
// }

::-webkit-file-upload-button {
	cursor: pointer;
}

.search-drodown .dropdown-menu {
	border: 1px solid #e5e7eb;
	margin-top: -44px !important;
	min-width: 800px;
	box-shadow: 0 2px 4px #0000001a;
	border-radius: 0 0 16px 16px;
	padding: 0;
}

.search-drodown .search-container {
	background: #F1F1F1;
	padding: 22px 24px;
}

.search-drodown .search-container input {
	border: 0;
	background: transparent;
	width: 90%;
	padding-left: 1rem;
}

.search-drodown .search-container input:focus {
	outline: 0;
}

.search-drodown .quick-action {
	margin-bottom: 22px;
}

.search-drodown .quick-action i {
	background: #64d763;
	color: #fff;
	border-radius: 50px;
	height: 16px;
	width: 16px;
	text-align: center;
	line-height: 16px;
}

.search-drodown .quick-action label,
.search-drodown .search-result-for label {
	color: #777;
	line-height: 17px !important;
	font-size: 14px !important;
}

.search-drodown .search-result-for a {
	color: #333;
	font-size: 16px !important;
	line-height: 20px !important;
}

.search-drodown .search-container-body {
	padding: 22px 24px 30px;
}

.search-drodown .dropdown-item {
	padding: .5rem 0 !important;
}

.search-drodown .dropdown-item:focus,
.search-drodown .dropdown-item:hover {
	background: transparent;
}
.req{
	font-size: 18px;
	color: #212529;
}
.highcharts-legend-item text {
	cursor: default !important;
}
.highcharts-title {
	color: transparent !important;
	fill: transparent !important;
}
.web-d-none {
	display: none;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
 	z-index: 99999 !important;
  }

.success-message-dialog .mat-dialog-container{
	margin: 0 auto;
	margin-top: 8.5rem;
}

tr.mat-row:hover{
	background-color: whitesmoke;
}
h2.modal-title{
	font-family: 'Proxima' !important;
}
@media screen and (max-width:992px) {
	.main-body {
		padding: 0;
	}
	.main-body-header {
		padding: 1rem;
		margin-bottom: 0;
	}
	.main-body-header .header-text,
	.stream-indiv-view-container .header-text {
		position: relative;
		top: 6px;
		font-size: 14px;
		line-height: 24px;
	}
	.navbar-light .navbar-toggler {
		border-color: transparent;
	}
	.navbar-light .navbar-toggler:focus {
		outline: 0;
		box-shadow: none;
	}
	.navbar-toggler {
		padding: 0;
		position: relative;
		top: 0;
		font-size: 1rem;
	}
	.header-shadow {
		width: 100%;
	}
	aside {
		display: none;
	}
	.stream-container {
		border-radius: 0 !important;
	}
	.section-header p,
	.header-list {
		padding: 12px 1rem !important;
	}
	.notification-container {
		display: none;
	}
	th.mat-header-cell,
	td.mat-cell,
	td.mat-footer-cell {
		padding: 0 1rem !important;
		white-space: nowrap;
	}
	.mat-table thead th {
		white-space: nowrap !important;
	}
	.mob-d-none {
		display: none !important;
	}
	.mob-d-block {
		display: block !important;
	}
	.mob-mr-8px {
		margin-right: 8px !important;
	}
	.mob-p-0 {
		padding: 0 !important;
	}
	.mob-p-1rem {
		padding: 1rem !important;
	}
	.mob-mb-8px {
		margin-bottom: 8px !important;
	}
	.mob-nowrap {
		flex-wrap: nowrap;
	}
	.mobile-menu {
		position: fixed;
		left: 0;
		top: 64px;
		background: #fff;
		width: 100%;
		height: calc(100vh - 64px);
		z-index: 999;
		overflow-y: auto;
		padding-top: 1rem;
		// display: none;
	}
	.mobile-menu ul:nth-child(1) {
		padding-left: 0;
		padding-bottom: 24px;
		border-bottom: 2px solid #e5e7eb;
	}
	.mobile-menu ul {
		padding-left: 0;
		// padding-bottom: 24px;
		// border-bottom: 2px solid #e5e7eb;
	}
	.mobile-menu ul li {
		width: 100%;
		list-style: none;
		height: 40px;
		line-height: 40px;
		padding-left: 1rem;
		margin-bottom: .5rem;
		font-size: 16px;
	}
	// .mobile-menu ul li:hover {
	// 	background: #F9FAFB;
	// 	border-radius: 4px;
	// }
	.mobile-menu ul li:focus {
		outline: 0 !important;
	}
	.mobile-menu ul li a {
		color: #2E394C;
		font-size: 16px;
		display: flex;
		position: relative;
	}
	.mobile-menu ul li a .profile-div {
		width: 32px;
		height: 32px;
		border: 1px solid #E5E7EB;
		background: url(https://matkaguru.in/avatar.png);
		background-size: cover;
		margin-left: 0;
		margin-right: 8px;
	}
	.mobile-menu ul li a .notification-dot {
		background: #FF4D53;
		height: 8px;
		width: 8px;
		position: absolute;
		border-radius: 50px;
		top: 8px;
		right: 16px;
	}
	.menu-active::before {
		height: 41px !important;
	}
	// .rightAlign{
	//     position: relative;
	//     right: 0;
	// }
	.export-btn {
		height: 32px;
		width: 32px !important;
		padding: 0 !important;
	}
	.export-btn img {
		margin: 0 auto !important;
	}
	// .modal-dialog {
	// 	margin: 4.5rem 1rem;
	// }
	.modal .modal-body {
		text-align: center;
	}
	.modal .modal-body .rounded-delete {
		margin-right: 0;
		margin-bottom: 20px;
	}
	.modal-footer {
		padding: 0.5rem 1rem 0.5rem;
	}
	.modal-footer .btn {
		width: 100%;
	}
	.md-drppicker {
		// position: absolute !important;
		// right: 0 !important;
		// z-index: 998 !important;
		position: fixed !important;
		right: 0 !important;
		z-index: 999 !important;
		overflow: scroll !important;
		margin-top: 0 !important;
		padding: 0 !important;
		// width: 100% !important;
		top: 0 !important;
		border-radius: 0 !important;
	}
	.md-drppicker .calendar {
		max-width: 100% !important;
		box-shadow: none;
		font-size: 14px !important;
	}
	.md-drppicker .ranges ul li button {
		border-bottom: 1px solid $primary-color !important;
		width: 100% !important;
		float: left !important;
		background: 0 0;
		margin-bottom: 12px !important;
		text-align: center !important;
		padding: 8px !important;
		// height: 58px !important;
	}
	.video-js:not(.vjs-fullscreen) {
		height: 198px !important;
		border-radius: 6px !important;
	}
	.video-js .vjs-modal-dialog {
		border-radius: 6px !important;
	}
	.clip-input {
		margin-left: 0;
		margin-right: 0;
	}
	.clip-input div {
		padding-left: 0;
	}
	.clip-input input,
	.clip-input button {
		margin-bottom: 8px;
	}
	.mob-order-2 {
		background: #f3f4f6 !important;
		margin-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.bottom-buttons {
		padding: 8px 1rem !important;
		width: 100% !important;
		margin-left: 0 !important;
	}
	.create-season-tickets-form label {
		margin-bottom: 5px !important;
	}
	// .create-season-tickets-form .form-row>.col, .form-row>[class*=col-] {
	// 	padding-right: 20px !important;
	// 	padding-left: 20px !important;
	// }
	.create-season-tickets-form input,
	.create-season-tickets-form .mat-form-field-infix,
	.create-season-tickets-form .input-group,
	.create-season-tickets-form textarea {
		width:100%;
		// width: calc(100vw - 32px) !important;
	}
	.create-season-tickets-form .validity-date-picker input{
		width: calc(100vw - 32px) !important;
	}
	.currency-input-select .mat-form-field-infix {
		width: 500px !important;
	}
	.mat-form-field[_ngcontent-ucl-c144] {
		width: 100% !important;
	}
	.main-body-create-season {
		min-height: calc(100vh - 42px) !important;
		background: #fff !important;
		overflow: auto;
	}
	.create-season-tickets-form .validity-date-picker {
		width: 100%;
		margin-bottom: 24px;
		margin-left: 0;
		margin-right: 0;
	}
	.create-season-tickets-form .currency-input-select {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.create-season-tickets-form .md-drppicker {
		position: absolute !important;
		left: 0 !important;
	}
	.video-height {
		min-height: 200px;
	}
	// .headercatagories .md-drppicker{
	// 	top: 0 !important;
	// 	// height: 100%;
	// }
	// #arenaModal .modal-dialog .mat-form-field-infix {
	// 	width: 113px !important;
	//   }
	.mat-column-createdByUser{
		min-width: 335px !important;
    	width: 335px !important;
    	max-width: 335px !important;
	}
	.main-body-channel{
		padding: 0px !important;
	}
	.mat-datepicker-popup{
        bottom: unset !important;
		right: 23px !important
    }
}
@media screen and (max-width:563px){
	.md-drppicker .ranges ul li button {
		width: 20% !important;
	}
} 
.season-ticket-attached{
	color: #007bff;
	text-decoration: underline;
	text-decoration-color: #007bff;
	cursor: pointer;
}


#arenaModal .modal-dialog .mat-form-field-infix {
    width: 195px;
}

#arenaModal .modal-dialog .mat-form-field-appearance-legacy .mat-form-field-underline{
	height: 0 !important;
}
#arenaModal .modal-dialog .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent;
    display: none;
}
#arenaModal .modal-dialog .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
}


.loader-chart{
	background: #33333399;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 100000;
	left: 0;
	top: 0;
}
.loader-chart img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
	border-radius: 50px;
}


.login-loader{
	background: #33333399;
	width: 100vw;
	height: 100vh;
	// position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
}
.login-loader img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
	border-radius: 50px;
}
.mat-column-name, .mat-column-event {
    white-space: pre-line !important;
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
  }
  .mat-column-country {
    white-space: pre-line !important;
    min-width: 150px !important;
    width: 150px !important;
    max-width: 150px !important;
  }
.mat-column-description {
    white-space: normal !important;
    min-width: 150px !important;
    width: 150px !important;
    max-width: 150px !important;
	text-overflow: ellipsis;
	overflow: hidden;
  }
  .ticketBuyerTable .mat-column-name {
    white-space: normal !important;
    min-width: auto !important;
    width: auto !important;
    max-width: auto !important;
  }
  .mat-column-team {
    white-space: pre-line !important;
    min-width: 200px !important;
    width: 200px !important;
    max-width: 200px !important;
  }
.mat-progress-spinner circle, .mat-spinner circle {   
	stroke: $primary-color !important;
}
@media screen and (max-width:560px){
	.md-drppicker .ranges ul li button{
		width: 20% !important;
	}
  }
@media screen and (max-width:389px) {
	.push-notifi-form .mat-form-field-infix{
		width: 60vw !important;
	}
}
.channel-table .mat-column-club {
    white-space: pre-line !important;
    min-width: 350px !important;
    width: 350px !important;
    max-width: 350px !important;
  }
@media screen and (max-width: 564px) {
	.md-drppicker {
		width: 100% !important;
	}
	.headercatagories .md-drppicker{
		top: 0 !important;
		height: 100%;
	}
}
.invite-form .mat-form-field-infix{
	width: 484px !important;
	border: 1px solid #A5B6D7;
}
.mat-select-panel .mat-optgroup-label{
    padding: 0 6px !important;
	margin-bottom: 0px !important;	
	font-family: "Proxima";
}
.cdk-overlay-connected-position-bounding-box{
	z-index: 100000;
}
