/*
Styles for ad plugins.
*/

.vjs-ad-playing.vjs-ad-playing .vjs-progress-control {
  pointer-events: none;
}

// Make the progress bar yellow for ads
.vjs-ad-playing.vjs-ad-playing .vjs-play-progress {
  background-color: #ffe400;
}

// Hide the captions button during ad playback
.vjs-ad-playing .vjs-captions-button {
  display: none;
}

// Hide the audio track button during ad playback
.vjs-ad-playing .vjs-audio-button {
  display: none;
}

/*
Hide BPB and poster while ad loading and resuming after an ad.
*/
.vjs-ad-loading .vjs-big-play-button,
.vjs-ad-loading .vjs-poster,
.vjs-ad-content-resuming .vjs-big-play-button,
.vjs-ad-content-resuming .vjs-poster {
  display: none;
}

.preroll-skip-button,
.advertisement-box,
.remaining-time {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: auto;
  background-color: rgba(0, 0, 0, .5);
  color: #aaa;
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  padding: 10px;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, .35);
  border-right: none;
}

.advertisement-box {
  right: auto;
  left: 0;
  font-style: normal;
  border-right: 1px solid rgba(255, 255, 255, .35);
  border-left: none;
}

.preroll-skip-button.enabled {
  cursor: pointer;
  color: #fff;
}

.preroll-skip-button.enabled:hover {
  cursor: pointer;
  background: #333;
}

.preroll-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}